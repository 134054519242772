<uf-panel class="container">
	<div class="header-pane uf-app-bar transparent">
		<uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large" />
	</div>
	<div class="content-pane">
		<div class="uf-container-lg">
			<div class="uf-grid gaps">
				@if (status && statusError) {
					<uf-message [content]="statusError.message" class="error col-12" icon="errorSolid" />
				}

				@if (status && !statusError) {
					<form (submit)="publish(true)" class="uf-form-card col-12 col-lg-6">
						<div class="pad-sides grow">
							<h3>Preview</h3>
							@if (status.preview && !status.pending?.preview) {
								<uf-data-display-list>
									<dt>Current Preview</dt>
									<dd>{{ status.preview.version }}-preview.{{ status.preview.preview }}</dd>
									<dt>Published on</dt>
									<dd>
										{{ status.preview.publishedAt | dataDisplay }}, by
										{{ status.preview.publishedBy?.username }}
									</dd>
								</uf-data-display-list>
							}
							@if (previewPublishError) {
								<uf-message [content]="previewPublishError.message" class="error pad-top" icon="errorSolid" />
							}
							<br />
							@if (inProgress && status.pending?.preview) {
								<uf-spinner />
							}
						</div>
						<div class="row space-children pad">
							<button [disabled]="inProgress || !selectedItems.length" type="submit" class="uf-button primary right" restrict="Publisher">Publish Preview</button>
						</div>
					</form>
					<form (submit)="publish()" class="uf-form-card col-12 col-lg-6">
						<div class="pad-sides grow">
							<h3>Live</h3>
							@if (status.stable && !(status.pending && status.pending.preview) && !downloadInProgress) {
								<uf-data-display-list>
									<dt>Current Live</dt>
									<dd>{{ status.stable.version }}</dd>
									<dt>Published on</dt>
									<dd>
										{{ status.stable.publishedAt | dataDisplay }}, by
										{{ status.stable.publishedBy?.username }}
									</dd>
								</uf-data-display-list>
							}
							@if (stablePublishError || downloadError) {
								<uf-message [content]="stablePublishError?.message || downloadError?.message" class="error pad-top" icon="errorSolid" />
							}
							@if (inProgress && !status.pending?.preview) {
								<uf-spinner />
							}
							<br />
						</div>
						<div class="row space-children pad">
							@if (status.stable && artifact) {
								<button [disabled]="inProgress" (click)="getAppPackage(status.stable.version)" type="button" class="uf-button right">Get AppPackage</button>
							}
							<button [ngClass]="{ right: !status.stable || !artifact }" [disabled]="inProgress || !selectedItems.length" type="submit" class="uf-button primary" restrict="Publisher">Publish Live</button>
						</div>
					</form>
					@if (!publishDataSource) {
						<div class="empty-publish col-12">
							<img src="assets/svg/thumbs-up.svg" />
							<span> You're all caught up! </span>
						</div>
					}
					@if (publishDataSource) {
						<uf-expander [actionBtn]="true" class="col-12">
							<!-- header -->
							<div expanderHeader class="uf-app-bar small accent">
								<a class="title"> Items that will be Published </a>
							</div>
							<div expanderBody class="uf-box">
								<uf-table #publishTable [config]="publishTableConfig" [datasource]="publishDataSource" (sortChange)="onTableSortChange(publishTableId, $event)" (selectionChange)="onSelectChange()" />
							</div>
						</uf-expander>
					}
					@if (archiveDataSource) {
						<uf-expander [actionBtn]="true" class="col-12">
							<!-- header -->
							<div expanderHeader class="uf-app-bar small accent">
								<a class="title"> Items that will be Archived </a>
							</div>
							<div expanderBody class="uf-box">
								<uf-table #archiveTable [config]="archiveTableConfig" [datasource]="archiveDataSource" (sortChange)="onTableSortChange(archiveTableId, $event)" (selectionChange)="onSelectChange()" />
							</div>
						</uf-expander>
					}
				}
			</div>
		</div>
	</div>
</uf-panel>
